import React, {Component} from "react"
import axios from "axios"

export default class Login extends Component {
    state = {email: "", password: "", error: null}

    handleChange = (e, type) => {
        this.setState({[type]: e.target.value})
    }


    handleLogin = async () => {
        const {email, password} = this.state
        this.setState({error: null})
        const response = await axios.post('https://4ma9wc3302.execute-api.eu-west-2.amazonaws.com/dev/login', {email, password})
        
        if(response.data.status === 200) {
            localStorage.setItem('keys', JSON.stringify(response.data.data.keys))
            localStorage.setItem('loginAt', new Date().getTime().toString())
            this.props.setKeys(response.data.data.keys)
        } else {
          this.setState({error: response.data.data.message})
        }
    }

    render() {
        const {email, password, error} = this.state
        return <div className="container">
            <div className="row">
                <div className="col-4 offset-4 mt-5">
                    <div className="form-group">
                        <input type="email" name="email" placeholder="Email Address" className="form-control" value={email} onChange={e => this.handleChange(e, 'email')}/>
                    </div>
                    <div className="form-group">
                        <input type="password" name="password" placeholder="Password" className="form-control" value={password} onChange={e => this.handleChange(e, 'password')}/>
                    </div>
                    {error && <div className="form-group">{error}</div>}
                    <div className="form-group">
                        <button className="btn btn-primary" onClick={this.handleLogin} disabled={email.length < 10 || password.length < 8}>Login</button>
                    </div>
                </div>
            </div>
        </div>
    }
}
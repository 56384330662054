import React, {Component} from 'react';
import './App.css';
import Login from "./components/Login";
import {Header} from "./components/Header";
import MainPanel from "./components/MainPanel";

class App extends Component {
    state = {keys: []}

    setKeys = keys => this.setState({keys})

    componentWillMount() {
        let keys = localStorage.getItem("keys")
        let loginAt = localStorage.getItem("loginAt")

        if(new Date().getTime() - 43200000 < Number(loginAt) && keys) {
            //Login valid for 12 hours
            this.setKeys(JSON.parse(keys))
        } else {
            this.logOut()
        }
    }

    logOut = () => {
        this.setKeys([])
        localStorage.clear()
    }

    render() {
        const {keys} = this.state
        return (
            <div className="App">
                <Header logoutValid={keys.length > 0} logOut={this.logOut}/>
                {keys.length === 0 ? <Login setKeys={this.setKeys}/> : <MainPanel keys={keys}/>}
            </div>
        );
    }
}

export default App;

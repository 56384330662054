import React from "react"

export const Header = props => (
    <header>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container">
                <a className="navbar-brand" href="#"><img src="https://d33wubrfki0l68.cloudfront.net/fceec044b2d6ad254898bbbe7a635051a6535be4/3f075/img/genflow.png" height={30}/></a>
                {props.logoutValid && <a className="navbar-brand ml-auto" onClick={props.logOut} href="#">Log Out</a>}
            </div>
        </nav>
    </header>
)
import React, {Component} from "react"
import {Switcher} from "./Switcher";
import {ChatManager, TokenProvider} from "@pusher/chatkit-client";
const Chatkit = require('@pusher/chatkit-server');

export default class MainPanel extends Component {
    state = {activeKey: 0, loading: false, activeRoom: null, messages: [], files: []}
    chatManager = null
    currentUser = null
    chatkit = null
    messagesEndRef = React.createRef();

    componentWillMount() {
        this.ChatKitLogin()
    }

    changeKey = e => {
        this.setState({activeKey: e.target.value}, () => {
            this.ChatKitLogin()
        })
    }

    ChatKitLogin = () => {
        const {keys} = this.props
        const {activeKey} = this.state

        this.setState({loading: true, activeRoom: null})
        const tokenProvider = new TokenProvider({
            url: keys[activeKey].url,
        });

        let chatManager = new ChatManager({
            instanceLocator: keys[activeKey].instanceLocator,
            userId: 'admin',
            tokenProvider: tokenProvider,
        });

        const chatkit = new Chatkit.default({
            instanceLocator: keys[activeKey].instanceLocator,
            key: keys[activeKey].key,
        })

        chatManager
            .connect()
                .then(currentUser => {
                    this.chatManager = chatManager
                    this.currentUser = currentUser
                    this.chatkit = chatkit
                    this.setState({loading: false})
                })
                .catch(err => {
                    this.setState({loading: false})
                });
    }

    renderRoomList = () => {
        const {loading, activeRoom} = this.state
        if(loading || !this.currentUser) return <div>Loading...</div>
        return this.currentUser.rooms.map(room => {
            return <button type="button" className={"list-group-item list-group-item-action " + (activeRoom === room.id ? "active" : "")} key={room.id} onClick={() => this.setActiveRoom(room.id)}>
                {room.name}
            </button>
        })
    }

    setActiveRoom = activeRoom => {
        this.setState({activeRoom, messages: [], files: []})
        this.currentUser.subscribeToRoomMultipart({
            roomId: activeRoom,
            hooks: {
                onMessage: this.onReceive,
                onMessageDeleted: this.onDelete
            },
        })
        this.setState({
            initialized: true
        });
    }

    onReceive = async data => {
        let { message, file } = await this.getMessageAndFile(data);

        if (file) {
            const { id, name, link, type } = file;
            await this.setState(previousState => ({
                // add the new file
                files: previousState.files.concat({
                    id: id,
                    name: name,
                    link: link,
                    type: type
                })
            }));
        }

        await this.setState(previousState => ({
            // add the new message
            messages: [...previousState.messages, message]
        }));

        if (this.state.messages.length > 9) {
            this.setState({
                show_load_earlier: true
            });
        }

        this.scrollToBottom()
    };

    onDelete = id => {
        let {messages} = this.state
        messages.splice(messages.findIndex(x => {if(x._id === id) return x}), 1)
        this.setState({messages})
    }

    getMessageAndFile = async ({ id, senderId, parts, attachment, createdAt }) => {
        let file_data = null;
        let msg_data = {
            _id: id,
            text: parts[0].payload.content,
            createdAt: new Date(createdAt),
            user: {
                _id: senderId,
                name: senderId
            }
        };

        if (parts.length > 1) {
            const { name, type, _downloadURL } = parts[1].payload; // the link where the actual file can be fetched, and the file type
            msg_data.image = _downloadURL; // add an image to the message

            file_data = {
                id: id, // message ID
                name: name,
                link: _downloadURL, // actual link to the file
                type: type // file type
            };
        }

        return {
            message: msg_data,
            file: file_data
        };
    };

    deleteMessage = messageId => {
        let confirm = window.confirm("Are you sure?")
        if(confirm) {
            this.chatkit.deleteMessage({
                roomId: this.state.activeRoom,
                messageId
            })
                .catch(err => {
                    alert("something went wrong")
                    console.error(err)
                })
        }
    }

    scrollToBottom = () => {
        this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
    }

    render() {
        const {keys} = this.props
        const {loading, messages} = this.state

        return <div className="container">
            <Switcher changeKey={this.changeKey} projects={keys}/>
            <div className="row">
                {loading && <div className="col-12">Loading...</div>}
                <div className="col-4">
                    <div className="card">
                       <div className="card-header">
                           Rooms
                       </div>
                        <div className="card-body">
                            <div className="list-group">
                                {this.renderRoomList()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-8">
                    <div className="card">
                        <div className="card-header">
                            Chat Window
                        </div>
                        <div className="card-body chat-window">
                            {messages.map(message => {
                                console.log("message", message)
                                return <div className="message" key={message._id}>
                                    <div className="d-flex">
                                        <div className="bubble">
                                            {message.image && <img src={message.image}/>}
                                            {message.text}
                                        </div>
                                        <a className="remove" onClick={() => this.deleteMessage(message._id)} ><i className="fa fa-trash"/> </a>
                                    </div>
                                    <div className="sender">
                                        @{message.user._id}
                                    </div>
                                </div>
                            })}
                            <div ref={this.messagesEndRef} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}